.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    padding: 30px 0;
}

.media-section {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 30px 0;
}

.mid-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.bottom-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    padding: 30px 0;
}

.image-grid {
    width: 95%;
    height: 100%;
}