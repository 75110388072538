@import url('https://fonts.googleapis.com/css2?family=El+Messiri&display=swap');
@font-face {
    font-family: 'blackgold';
    src: local('Blackgold-qGAl'), url('../fonts/Blackgold-qGAl.otf') format('opentype')
}
@font-face {
    font-family: 'metropolis';
    src: local('metropolis'), url('../fonts/Metropolis-Regular.ttf') format('truetype')
}

html {
    padding: 0;
    margin: 0;
}

body {
    overflow-x: hidden;
    background-image: url('../assets/annie-spratt-y450DOvyzT4-unsplash.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    margin: 0;
    background-color: darkslategrey;
}